import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth';

var firebaseConfig = {
  apiKey: "AIzaSyCBVCKik8wDNIKGV21WMntISCcQvgCK4WA",
  authDomain: "aipete-g04t.firebaseapp.com",
  projectId: "aipete-g04t",
  storageBucket: "aipete-g04t.appspot.com",
  messagingSenderId: "444493177718",
  appId: "1:444493177718:web:04afe2e974df6f67579057",
  measurementId: "G-PS1QGK52J8"
}

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { app, auth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut };
