<template>
    <div v-if="isTemplateModalOpen" @close="closeTemplateModal" class="modal">
        <div slot="body">
            <!-- Your grid with conversation starters -->
            <div v-for="template in templates" :key="template.id" @click="startConversation(template)">
                {{ template.message }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatTemplateModal',
    data() {
        return {
            isTemplateModalOpen: false
        };
    },
    mounted() {
        import("../../../assets/js/chatWithPete.js").then(m => {
            m.chatWithPete;
        });
    }
}
</script>
  